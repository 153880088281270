/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import { ContextProvider } from "../Context/Context";
import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";

export default function StateManagement({ children }) {
  const [questionCount, setQuestionCount] = useState(0);
  const [personaResultsPage, setPersonaResultsPage] = useState(false);
  const [stampPersonaCount, setStampPersonaCount] = useState(0);
  const [sunsetPersonaCount, setSunsetPersonaCount] = useState(0);
  const [curiousPersonaCount, setCuriousPersonaCount] = useState(0);
  const [activePersonaCount, setActivePersonaCount] = useState(0);
  const [mostSelectedPersona, setMostSelectedPersona] = useState("");
  const [personasClicked, setPersonasClicked] = useState([]);
  const [token, setToken] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [personas, setPersonas] = useState([]);
  const [leadID, setLeadID] = useState("");
  const [contactID, setContactID] = useState("");
  const [surveyVersion, setSurveyVersion] = useState(null);
  const [Q7Answer, setQ7Answer] = useState("");
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (token === null) {
      const url =
        process.env.REACT_APP_SURVEY_API_URL + "/api/users/token/obtain/";
      const headers = {
        "Content-Type": "application/json",
      };
      const bodyData = {
        email: process.env.REACT_APP_SURVEY_API_EMAIL,
        password: process.env.REACT_APP_SURVEY_API_PASSWORD,
      };
      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(bodyData),
        redirect: "follow",
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
          if (data) setToken(data["access"]);
        })
        .catch((e) => console.log(e));
    }

    if (token !== null) {
      const url =
        process.env.REACT_APP_SURVEY_API_URL +
        "/api/v2/survey/?surveyversion=" +
        surveyVersion;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };
      fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setQuestions(data?.questions);
            setPersonas(data?.personas);
            setLoading(false)
          }
        })
        .catch((e) => console.log(e));
    }
  }, [token]);

  const handlePersonaType = (personaType) => {
    if (personaType === "stamp") {
      setStampPersonaCount((count) => count + 1);
    } else if (personaType === "sunset") {
      setSunsetPersonaCount((count) => count + 1);
    } else if (personaType === "curious") {
      setCuriousPersonaCount((count) => count + 1);
    } else if (personaType === "active") {
      setActivePersonaCount((count) => count + 1);
    }
  };

  const handleBackButton = () => {
    const index = questionCount - 2;
    const lastClicked = personasClicked[index];
    personasClicked.pop();

    if (lastClicked !== undefined) {
      if (lastClicked === "stamp") setStampPersonaCount(stampPersonaCount - 1);

      if (lastClicked === "active")
        setActivePersonaCount(activePersonaCount - 1);

      if (lastClicked === "curious")
        setCuriousPersonaCount(curiousPersonaCount - 1);

      if (lastClicked === "sunset")
        setSunsetPersonaCount(sunsetPersonaCount - 1);
    }
  };

  const handlePersonaResultsPage = () => {
    setPersonaResultsPage(true);
    determineMostSelectedPersona();
  };

  const [SFToken, setSFToken] = useState("");
  
  const fetchSFToken = () => {
    const url = "https://tcstravel.my.salesforce.com/services/oauth2/token";
    const params = new URLSearchParams({
      client_id:
        "3MVG9HB6vm3GZZR.0NjM8ACQvYQO0SBemxK9UqdVJaQzIs.Tf5hOYyv6vVj7VOrmbOAERRnsGT7EmzGFT2Qhk",
      client_secret:
        "443B22BCFAF8F14D9E8EEE85290E0DB908A91BD0691F303BB001F0CBBDE9576D",
      username: "persona@tcsworldtravel.com.tcs",
      password: "FunT1m3s:)" + "ZbyAc5qjHD2SqRbqFryA2B8y",
      grant_type: "password",
    });

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.access_token) {
          setSFToken(data.access_token);
        } else {
          console.log("No access token in response", data);
        }
      })
      .catch((error) => console.error("Error fetching:", error));
  };

  useEffect(() => {
    fetchSFToken();
  }, [SFToken]);


  
  useEffect(() => {
    const params2 = window.location.search.replace("?", "");

    const urlParams = new URLSearchParams(params2);
    setLeadID(urlParams.get("lead_id"));
    setContactID(urlParams.get("contact_id"));
    setSurveyVersion(urlParams.get("survey_version") || 1);
  }, [window.location.search]);

  // Function that appends the Salesforce Contact Object
  useEffect(() => {
    if (
      window.location.hostname !== "localhost" &&
      contactID &&
      mostSelectedPersona
    ) {
      // const updateData = {
      //   Persona__c: `${
      //     mostSelectedPersona.personaType === "stamp"
      //       ? "Stamp Collector"
      //       : mostSelectedPersona.personaType === "active"
      //       ? "Active Adventurer"
      //       : mostSelectedPersona.personaType === "curious"
      //       ? "Curious Historian"
      //       : mostSelectedPersona.personaType === "sunset"
      //       ? "Sunset Chaser"
      //       : null
      //   }`,
      //   Ready_for_Big_Trip_Response__c: `${Q7Answer}`,
      // };

      fetch(
        `https://tcstravel.my.salesforce.com/services/data/v59.0/sobjects/Contact/${contactID}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${SFToken}`,
            "Content-Type": "application/json",
            
          },
          body: JSON.stringify({ Persona__c: `${
            mostSelectedPersona.personaType === "stamp"
              ? "Stamp Collector"
              : mostSelectedPersona.personaType === "active"
              ? "Active Adventurer"
              : mostSelectedPersona.personaType === "curious"
              ? "Curious Historian"
              : mostSelectedPersona.personaType === "sunset"
              ? "Sunset Chaser"
              : null
          }`,
          Ready_for_Big_Trip_Response__c: `${Q7Answer}`, }),
        }
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    }
  }, [mostSelectedPersona]);

  useEffect(() => {
    if (
      window.location.hostname !== "localhost" &&
      leadID &&
      mostSelectedPersona
    ) {
      // const updateData = {
      //   Persona__c: `${
      //     mostSelectedPersona.personaType === "stamp"
      //       ? "Stamp Collector"
      //       : mostSelectedPersona.personaType === "active"
      //       ? "Active Adventurer"
      //       : mostSelectedPersona.personaType === "curious"
      //       ? "Curious Historian"
      //       : mostSelectedPersona.personaType === "sunset"
      //       ? "Sunset Chaser"
      //       : null
      //   }`,
      //   Ready_for_Big_Trip_Response__c: `${Q7Answer}`,
      // };

      fetch(`https://tcstravel.my.salesforce.com/services/data/v59.0/sobjects/Lead/${leadID}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${SFToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({  
          Persona__c: `${
                mostSelectedPersona.personaType === "stamp"
                  ? "Stamp Collector"
                  : mostSelectedPersona.personaType === "active"
                  ? "Active Adventurer"
                  : mostSelectedPersona.personaType === "curious"
                  ? "Curious Historian"
                  : mostSelectedPersona.personaType === "sunset"
                  ? "Sunset Chaser"
                  : null
              }`,
              Ready_for_Big_Trip_Response__c: `${Q7Answer}`,
         }),
      })
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    }
  }, [mostSelectedPersona]);

  const determineMostSelectedPersona = () => {
    const counts = [
      { personaType: "stamp", count: stampPersonaCount },
      { personaType: "sunset", count: sunsetPersonaCount },
      { personaType: "curious", count: curiousPersonaCount },
      { personaType: "active", count: activePersonaCount },
    ];

    const maxCount = Math.max(...counts.map((item) => item.count));
    const mostSelectedPersona = counts.find((item) => item.count === maxCount);

    setMostSelectedPersona(mostSelectedPersona);
  };

  const questionTotal = 7;
  const questionType = "q" + questionCount;
  const getCurrentQuestion = (questions, questionType) => {
    if (questions?.length > 0) {
      const currentQuestion = questions.filter((question) => {
        if (question["question_type"] === questionType) {
          //change to questionType when done testing
          if (question["question_title"])
            return question["question_title"] !== null;
        }
      });
      return currentQuestion[0]?.question_title;
    }
    return null;
  };
  const currentQuestion = getCurrentQuestion(
    questions,
    questionType,
    questionCount
  );

  const getImages = (questions) => {
    if (questions?.length > 0) {
      let answers = [];
      for (let i = 0; i <= questions.length; i++) {
        const question = questions[i];
        const type = question?.question_type;
        const questionAnswers = question && question?.answers;
        questionAnswers?.forEach((answer) => {
          answer.type = type;
          answers.push(answer);
        });
      }
      return answers;
    } else {
      return null;
    }
  };

  const images = getImages(questions);

  const submitAnswer = (question, answer, persona) => {
    if (window.location.hostname !== "localhost") {
      const url =
        process.env.REACT_APP_SURVEY_API_URL +
        "/api/v2/survey/results?surveyversion=" +
        surveyVersion;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const bodyData = {
        user: {
          contact_id: contactID ? contactID : null,
          lead_id: leadID ? leadID : null,
          external_source: "salesforce",
        },
        survey_version: 1,
        question: question,
        answer: answer,
        result: persona,
      };
      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(bodyData),
        redirect: "follow",
      };
      fetch(url, options)
        .then((response) => response.json())
        .catch((e) => console.log(e));
    }
  };

  const submitPersonaResult = async (personaResult) => {
    if (
      window.location.hostname !== "localhost" &&
      (contactID !== null || leadID !== null)
    ) {
      const url =
        process.env.REACT_APP_SURVEY_API_URL +
        "/api/v2/survey/done?surveyversion=" +
        surveyVersion;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const bodyData = {
        user: {
          contact_id: contactID ? contactID : null,
          lead_id: leadID ? leadID : null,
          external_source: "salesforce",
        },
        survey_version: 1,
        persona_result: personaResult && personaResult,
      };
      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(bodyData),
        redirect: "follow",
      };
      fetch(url, options)
        .then((response) => response.json())
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    if (personaResultsPage) {
      submitPersonaResult(mostSelectedPersona?.personaType);
    }
  }, [personaResultsPage]);

  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    height: 400,
    marginBottom: "8px",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      margin: "8px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
  }));

  const ImageSrc = styled("span")({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
    transition: "transform 0.3s linear",
    transform: "scale(1)",
    "&:hover": {
      transform: "scale(1.1)",
    },
  });

  const Image = styled("span")(({ theme }) => ({
    // position: "absolute",
    // left: 0,
    // right: 0,
    // top: 0,
    // bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  }));

  // const ImageBackdrop = styled("span")(({ theme }) => ({
  //   position: "absolute",
  //   left: 0,
  //   right: 0,
  //   top: 0,
  //   bottom: 0,
  //   backgroundColor: theme.palette.common.black,
  //   opacity: 0.4,
  //   transition: theme.transitions.create("opacity"),
  // }));

  const contextProps = {
    questionCount,
    setQuestionCount,
    questionTotal,
    questionType,
    currentQuestion,
    ImageButton,
    ImageSrc,
    // ImageBackdrop,
    Image,
    personaResultsPage,
    setPersonaResultsPage,
    stampPersonaCount,
    setStampPersonaCount,
    sunsetPersonaCount,
    setSunsetPersonaCount,
    curiousPersonaCount,
    setCuriousPersonaCount,
    activePersonaCount,
    setActivePersonaCount,
    mostSelectedPersona,
    setMostSelectedPersona,
    handlePersonaType,
    handlePersonaResultsPage,
    personasClicked,
    setPersonasClicked,
    handleBackButton,
    token,
    personas,
    images,
    submitAnswer,
    Q7Answer,
    setQ7Answer,
    loading,
    setLoading,
    leadID,
    contactID,
    surveyVersion
  };

  return (
    <div>
      <ContextProvider value={contextProps}>{children}</ContextProvider>
    </div>
  );
}
