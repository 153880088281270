import { useContext } from 'react'
import { Context } from '../../Context/Context'
import Typography from '@mui/material/Typography'
// eslint-disable-next-line
// import { Container, CircularProgress } from '@mui/material'
import './QuestionAnswer.css'
import { useNavigate } from 'react-router-dom'

export default function QuestionAnswer(){

  const {
    images,
    questionCount,
    setQuestionCount,
    questionType,
    handlePersonaType,
    personaResultsPage,
    handlePersonaResultsPage,
    Image,
    ImageButton,
    ImageSrc,
    // ImageBackdrop,
    personasClicked,
    setPersonasClicked,
    submitAnswer,
    currentQuestion,
    setQ7Answer,
    // loading
    leadID,
    contactID,
    surveyVersion
  } = useContext(Context)

  const navigate = useNavigate()
  const queryParams = `?contact_id=${contactID}&lead_id=${leadID}&survey_version=${surveyVersion}`;
  // if(!images || images.length < 1)
  // return null
  // if(loading === true)
  //   return <Container sx={{display: 'flex', justifyContent: 'center', background: '#F0EEEB'}}><CircularProgress size={100} sx={{margin: '0 auto 0 auto', color: "#A8A8A8"}} /></Container>
  // {loading === true ? <Container sx={{display: 'flex', justifyContent: 'center'}}><CircularProgress size={100} sx={{margin: '0 auto 0 auto', color: "#A8A8A8"}} /></Container> : null}

  return(
    <>
      {images && images.map((image, i) => {
        const key = `image--${i}`
        const styles = {
          display: image.type !== questionType ? 'block' : 'inline-flex', 
          maxWidth: image.type !== questionType ? '1px' : 'unset',
          width: image.type !== questionType ? '1px' : '33vw',
          height: image.type !== questionType ? '1px' : '33vh',
          position: image.type === questionType ? 'relative' : 'absolute',
          zIndex: image.type === questionType ? '99' : '-99',
          opacity: image.type === questionType ? '1' : '0',
          pointerEvents: image.type === questionType ? 'auto' : 'none',
        }

        return(
          <ImageButton
            key={key}
            disableRipple
            onClick={() => {
              setQuestionCount(questionCount + 1)
              handlePersonaType(image?.persona_type)
              setPersonasClicked([...personasClicked, image?.persona_type])
              if(questionCount === 7 && !personaResultsPage){
                handlePersonaResultsPage()
                setQ7Answer(image?.answer)
                navigate(`/results${queryParams}`)
              }
              submitAnswer(currentQuestion, image?.answer, image?.persona_type)
            }}
            style={styles}
          >
            <ImageSrc style={{ backgroundImage: `url(${image?.image_url})`, }} />
            {/* <ImageBackdrop className="MuiImageBackdrop-root" /> */}
            <Image>
              <Typography
                className='questionAnswer'
                component="span"
                variant="subtitle1"
                color="inherit"
                sx={{
                  position: 'absolute',
                  bottom: '0',
                  background: '#005470',
                  width: '100%',
                  fontSize: '16px'
                }}
              >
                {image?.answer}
              </Typography>
            </Image>
          </ImageButton>
        )
      })}
    </>
  )

}