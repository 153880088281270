import { styled } from '@mui/material/styles'
import { useContext } from 'react'
import { Context } from '../Context/Context'
import { ButtonBase } from '@mui/material'
import '../Home/Home.css'
import { useNavigate } from 'react-router-dom'


export default function Home(){
 
  const { 
    questionCount,
    setQuestionCount,
    leadID,
    contactID,
    surveyVersion
  } = useContext(Context)

  const navigate = useNavigate();
  const queryParams = `?contact_id=${contactID}&lead_id=${leadID}&survey_version=${surveyVersion}`;

  const Button = styled(ButtonBase)(() => ({
    background: 'white',
    color: '#005470',
    padding: '10px 30px 10px 30px',
    borderRadius: '4px',
    fontSize: '18px',
    '&:hover': {
      background: '#005470',
      color: 'white',
    }
  }))

  const Heading = styled('h6')(() => ({
    color: 'white',
    fontSize: '1.2rem',
    fontWeight: 'normal',
    margin: '0 0 10px 0',
    fontFamily: 'UntitledSans-Light'
  }))

  const buttsonStyles = {
    fontFamily: 'UntitledSans-Medium'
  }

  if(questionCount > 0)
    return null

    const Image = styled('img')(({ theme }) => ({
      width: '80%',
      marginTop: '32px',
      [theme.breakpoints.up('md')]: {
        width: '60% !important', // Overrides inline-style
      },
      [theme.breakpoints.up('lg')]: {
        width: '50% !important', // Overrides inline-style
      },
      [theme.breakpoints.up('xl')]: {
        width: '25% !important', // Overrides inline-style
      },
    }))

  return(
    <div className='background-container' aria-label='open lake background image'>
      <div className='flex-container'>
        <Image src="/static/images/buttons/TCS Logo 1c horiz white.png" alt="home logo" />
        <div className='content-container'>
          <h2>Which Traveler Are You?</h2>
          <Heading sx={{ color: 'white'}}>
          Answer a few questions about how you would like to travel on an epic vacation and we will curate trip recommendations that match your personality.
          </Heading>
          
          <Button 
          sx={buttsonStyles}
          onClick={() => {
            setQuestionCount(questionCount + 1)
            navigate(`/quiz${queryParams}`)
          }}>
              TAKE QUIZ
          </Button>
        </div>
      </div>
    </div>
  )
}