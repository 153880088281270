import { useContext, useEffect } from 'react'
import { Context } from '../Context/Context'
import { Button, Grid, Container, CircularProgress } from '@mui/material'
import QuestionHeadline from './QuestionHeadline/QuestionHeadline'
import QuestionAnswer from './QuestionAnswer/QuestionAnswer'
import styled from '@emotion/styled'
import ImageLogo from '../ImageLogo/ImageLogo'
import './Question.css'
import { useLocation } from 'react-router-dom';

export default function Question(){

  const {
    questionCount,
    questionTotal,
    setQuestionCount,
    handleBackButton,
    loading,
    

  } = useContext(Context)

  const location = useLocation()
  useEffect(() => {
    const targetUrl = "/quiz"
    if(targetUrl === location.pathname && questionCount === 0)
      setQuestionCount(questionCount + 1)
  }, [])

  const FlexContainer = styled('div')(({ theme }) => ({
    background: questionCount > 0 && 'rgb(240, 238, 235)',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: questionCount === 8 || questionCount < 1 ? '0' : '100vh',
      width: questionCount === 8 || questionCount < 1 ? '0' : 'unset',
      position: questionCount === 8 || questionCount < 1 ? 'absolute' : 'relative',
      zIndex: questionCount === 8 || questionCount < 1 ? '-99' : '99',
      opacity: questionCount === 8 || questionCount < 1 ? '0' : '1',
      pointerEvents: questionCount === 8 || questionCount < 1 ? 'none' : 'auto',
      top: questionCount === 8 || questionCount < 1 ? '0' : 'unset',
    },
  }))

  // if(questionCount === 0 || questionCount === 8 || !images) return null
  if(loading === true)
    return <Container sx={{display: 'flex', justifyContent: 'center', background: '#F0EEEB', height: '100vh', alignItems: 'center'}}><CircularProgress size={200} sx={{margin: '0 auto 0 auto', color: "#005470"}} /></Container>
  
  return (

    <FlexContainer>
      <div style={{padding: '20px 0 20px 0'}}>
        {questionCount > 0 && <QuestionHeadline />}
        <QuestionAnswer /> 
        <Grid 
          container 
          direction='row' 
          justifyContent={{xs: 'space-around', sm: 'center'}} 
          alignItems='center' 
          columns={8} 
          justifyItems='center'
          sx={{marginTop: '20px'}}>
            <Grid item>
            {questionCount <= 0 ? null : (
                <Button onClick={() => {
                  setQuestionCount(questionCount - 1)
                  handleBackButton()
                  }} sx={{display: 'block', color: '#636566'}}
                >
                  &#60; Back
                </Button> 
              )}
            </Grid>
            <Grid item xs={4} zeroMinWidth>
              <ImageLogo />
            </Grid>
            <Grid item>
              {questionCount > 0 && <p className='counter' style={{margin: 0, color: '#636566', fontFamily: 'UntitledSans-Light'}}>{questionCount}/{questionTotal}</p>}
            </Grid>
        </Grid> 
      </div>
    </FlexContainer>

  )
}
