import { useContext } from 'react'
import { Context } from '../Context/Context'
import { Grid, Typography, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

export default function PersonaPage(){

  const {
    personas,
    setQuestionCount,
    mostSelectedPersona,
    setPersonaResultsPage,
    setStampPersonaCount,
    setCuriousPersonaCount,
    setActivePersonaCount,
    setSunsetPersonaCount,
    setPersonasClicked,
    leadID,
    contactID,
    surveyVersion
  } = useContext(Context)

  const navigate = useNavigate()
  const queryParams = `?contact_id=${contactID}&lead_id=${leadID}&survey_version=${surveyVersion}`;

  const personaResult = personas?.filter(persona => persona?.persona_name === mostSelectedPersona?.personaType)
  const persona = personaResult && personaResult[0]

  const PersonaImage = styled('img')(({theme}) => ({
    width: '100%',
    height: '300px',
    [theme.breakpoints.up('sm')]: {
      width: '450px'
    },
    [theme.breakpoints.up('md')]: {
      width: '600px'
    },
  }))
  
  const Paragraph = styled('p')(({theme}) => ({
    width: '100%',
    textAlign: 'left',
    lineHeight: '1.2',
    letterSpacing: '.2px',
    fontFamily: 'UntitledSans-Light',
    [theme.breakpoints.up('sm')]: {
      width: '450px'
    },
    [theme.breakpoints.up('md')]: {
      width: '600px'
    },
  }))
  
  const FlexContainer = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '40px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
  }))
  
  const ImageLogo = styled('img')(({theme}) => ({
    width: '150px',
    [theme.breakpoints.up('sm')]: {
      width: '200px',
      marginRight: '10px'
    },
    [theme.breakpoints.up('md')]: {
      width: '200px',
      marginRight: '80px'
    },
    [theme.breakpoints.up('lg')]: {
      width: '200px',
      marginRight: '60px'
    },
  }))

  const gridContainerStyles = { 
    display: {xs: 'block', sm: 'flex'},
    // height: '100vh', 
    // width: '100vw', 
    background: 'rgb(240, 238, 235)'
  }

  const travelerHeadingStyles = { 
    paddingTop: {xs: '30px', sm: 0},
    fontSize: '18px', 
    color: '#636566',
    fontFamily: 'DomaineDisplay-Italic' 
  }

  const personaTitleStyles = {
    display: 'block',
    color: '#005470',
    fontFamily: 'DomaineDisplay-Medium'
  }

  const extraBackgroundStyles = {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '-1',
    background: '#F0EEEB'
  }

  if(mostSelectedPersona?.length === 0)
    return null

  return(
    <>
      <div style={{padding: '20px'}}>
        <Grid container justifyContent='center' alignContent='center' direction='column' sx={gridContainerStyles}>
          <Grid item>
            <Typography variant='h2' component='h2' sx={travelerHeadingStyles}>Which Traveler are You Quiz</Typography>
            <h1 style={{fontFamily: "DomaineDisplay-Italic"}}>You're the <span style={personaTitleStyles}>{persona?.persona_title}</span></h1>
          </Grid>
          <Grid item>
            <div style={{ padding: '12px', background: 'rgb(240, 238, 235)' }}>
              <PersonaImage src={persona?.persona_image} alt={persona?.persona_title} />
              <Paragraph>
                  {persona?.persona_text}
              </Paragraph>
              <span style={{display: 'block', textAlign: 'left', fontFamily: 'UntitledSans-Medium'}}>Customized trip recommendations are in your inbox.</span>
              <FlexContainer>
                <ImageLogo src="/static/images/buttons/TCS-Logo-1c-horiz-black.png" alt="TCS logo"/>
                <Button 
                sx={{fontFamily: 'UntitledSans-Medium'}}
                onClick={() => {
                  setActivePersonaCount(0)
                  setCuriousPersonaCount(0)
                  setStampPersonaCount(0)
                  setSunsetPersonaCount(0)
                  setPersonasClicked([])
                  setQuestionCount(1)
                  setPersonaResultsPage(false)
                  navigate(`/quiz${queryParams}`)
                }}>TAKE QUIZ AGAIN &gt;</Button>
              </FlexContainer>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={extraBackgroundStyles}/>
    </>
  )

}