import './App.css'
import Home from './components/Home/Home'
import Question from './components/Question/Question'
import PersonaPage from './components/PersonaPage/PersonaPage'
import { Grid } from '@mui/material'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

function App() {

  // const {
  //   personaResultsPage,
  // } = useContext(Context)

  const router = createBrowserRouter([
    {
      path: "/",
      element: 
      <center>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Home/>
          </Grid>
        </Grid>
      </center>,
    },
    {
      path: "/quiz",
      element: 
      <center>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Question/>
          </Grid>
        </Grid>
      </center>
    },
    {
      path: 'results',
      element:
      <center>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <PersonaPage/>
          </Grid>
        </Grid>
      </center>
    }
  ]);


  return (
    
    <div className="App"> 
      <RouterProvider router={router} />
    </div>
  )
}

export default App
