import styled from '@emotion/styled'

export default function ImageLogo(){

  const Image = styled('img')(({ theme }) => ({
    width: '80%',
    [theme.breakpoints.up('md')]: {
      width: '60% !important', // Overrides inline-style
    },
    [theme.breakpoints.up('lg')]: {
      width: '50% !important', // Overrides inline-style
    },
    [theme.breakpoints.up('xl')]: {
      width: '25% !important', // Overrides inline-style
    },
  }))

  return(
    <>
      <Image src="/static/images/buttons/TCS-Logo-1c-horiz-black.png" alt='TCS logo' />
    </>
  )

}