import { createTheme } from '@mui/material'

const createCustomTheme = (primaryColor) => {
  return createTheme({
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: '#E05916',
          },
        },
      },
    },
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: '#FAFAFA',
      },
    },
    typography: {
      fontFamily: [
        'DomaineDisplay-Black',
        'DomaineDisplay-BlackItalic',
        'DomaineDisplay-Bold',
        'DomaineDisplay-BoldItalic',
        'DomaineDisplay-ExtraBold',
        'DomaineDisplay-ExtraBoldItalic',
        'DomaineDisplay-Medium',
        'DomaineDisplay-MediumItalic',
        'DomaineDisplay-Regular',
        'DomaineDisplay-RegularItalic',
        'DomaineDisplay-Semibold',
        'DomaineDisplay-SemiboldItalic',
        'UntitledSans-Black',
        'UntitledSans-BlackItalic',
        'UntitledSans-Bold',
        'UntitledSans-BoldItalic',
        'UntitledSans-Light',
        'UntitledSans-LightItalic',
        'UntitledSans-Medium',
        'UntitledSans-MediumItalic',
        'UntitledSans-Regular',
        'UntitledSans-RegularItalic',
      ].join(','),
    },
  })
}

export default createCustomTheme