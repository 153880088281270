import { useContext } from 'react'
import { Context } from '../../Context/Context'
import './QuestionHeadline.css'

export default function QuestionHeadline(){

  const {
    currentQuestion
  } = useContext(Context)

  return(
    <div style={{ margin: '0 auto 0 auto' }}>
      <h2 style={{ fontSize: '18px', color: '#636566', fontFamily: 'DomaineDisplay-Italic' }}>Which Traveler Are You?</h2>
      <h2 className='currentQuestion'>{currentQuestion}</h2>
    </div>
  )
}